<template>
  <h1>Random String Generator</h1>
  <el-row style="margin-bottom: 20px;">
    <el-col
      :span="24"
    >
      <p>simplest online random string generator.</p>
      <div
        class="grid-content"
        style="padding: 20px; background-color: #1a1a1a"
      >
        <el-form
          ref="form"
          :model="form"
          label-width="120px"
        >
          <!-- Checkboxes:Options -->
          <el-form-item
            label="Option"
            class="el-form-item-label"
          >
            <el-checkbox v-model="checkedUppercaseLetters">
              Uppercase letters (A-Z)
            </el-checkbox>
            <br>
            <el-checkbox v-model="checkedLowercaseLetters">
              Lowercase letters (a-z)
            </el-checkbox>
            <br>
            <el-checkbox v-model="checkedNumericDigits">
              Numeric digits (0-9)
            </el-checkbox>
            <div>
              <el-space
                wrap
                :size="10"
              >
                <el-checkbox v-model="checkedAdditional">
                  Additional Symbol
                </el-checkbox>
                <el-input
                  v-model="additionalString"
                  type="text"
                  style="width: 180px; font-size: 0.9em;"
                />
              </el-space>
            </div>
          </el-form-item>
          <!-- Slider:Length -->
          <el-form-item
            label="Length"
          >
            <el-slider
              v-model="outputLength"
              :min="1"
              :max="128"
              show-input
            />
          </el-form-item>
          <!-- Button:Generate -->
          <el-form-item>
            <el-button
              type="success"
              @click="onClickGenerateButton"
            >
              Generate
            </el-button>
          </el-form-item>
          <!-- Output -->
          <el-form-item
            label="Output"
            class="el-form-item-label"
          >
            <el-input
              v-model="output"
              type="textarea"
              :rows="5"
            />
          </el-form-item>
          <el-form-item>
            <el-button
              style="float: right"
              type="primary"
              @click="onClickCopyButton"
            >
              Copy
            </el-button>
          </el-form-item>
        </el-form>
      </div>
    </el-col>
  </el-row>
  <!-- Histories -->
  <el-row style="margin-bottom: 20px;">
    <el-col :span="24">
      <el-table
        :data="histories"
        style="width: 100%;"
        :header-cell-style="{ background: '#121212'}"
        :cell-style="{ background: '#1a1a1a' }"
        :border="false"
      >
        <el-table-column
          prop="date"
          label="Date"
          width="120"
        />
        <el-table-column
          prop="name"
          label="Version"
          width="80"
        />
        <el-table-column
          prop="description"
          label="Description"
        />
      </el-table>
    </el-col>
  </el-row>
</template>

<script type="ts">

import {defineComponent, ref, computed, reactive} from "vue";
import { useHead } from '@vueuse/head'

export default defineComponent ({
  name: "TextCount",
  setup() {
    const head = reactive({
      title: `Random String Generator - Dev Tools`,
      description: `This tool is Random string generating`,
      keywords: `web,string,random,generate,generator`,
    })
    useHead({
      // Can be static or computed
      title: computed(() => head.title),
      meta: [
        {
          name: `description`,
          content: computed(() => head.description),
        },
        {
          name: `keywords`,
          content: computed(() => head.keywords),
        },
      ],
    })
    return {
      checkedUppercaseLetters: ref(true),
      checkedLowercaseLetters: ref(true),
      checkedNumericDigits: ref(true),
      checkedAdditional: ref(true),
      additionalString: ref('!@#$&=?'),
      outputLength: ref("16"),
      output: ref(''),
      histories: [{
        date: '2021/03/28',
        name: '0.9.1',
        description: 'init'
      }]
    }
  },
  methods: {
    onClickGenerateButton() {
      this.output = ""
      let string = ""
      if (this.checkedUppercaseLetters) {
        string += "ABCDEFGHIJKLMNOPQRSTUVWXYZ"
      }
      if (this.checkedLowercaseLetters) {
        string += "abcdefghijklmnopqrstuvwxyz"
      }
      if (this.checkedNumericDigits) {
        string += "0123456789"
      }
      if (this.checkedAdditional) {
        string += this.additionalString
      }
      console.log({string: string})
      for(var i = 0; i < this.outputLength; i++){
        this.output += string[Math.floor(Math.random() * string.length)];
      }
    },
    onClickCopyButton() {
      let text = this.output
      navigator.clipboard.writeText(text).catch((e) => {
        console.error(e)
      })
      this.$message({
        message: 'Copied.',
        type: 'success'
      })
    }

  }
})
</script>

<style scoped>

</style>